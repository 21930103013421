import React, { useEffect, useState } from "react";
import Card from "./card";
import { ReactComponent as DownIcon } from "../../../assets/icons/dashboard/vaults/solar_course-up-bold.svg";
import { ReactComponent as TravelIcon } from "../../../assets/icons/dashboard/vaults/streamline_travel-map-navigation-arrow-compass-arrow-map-bearing-navigation-maps-heading-gps.svg";
import { ReactComponent as VectorIcon } from "../../../assets/icons/dashboard/vaults/Vector.svg";
import { ReactComponent as GiftIcon } from "../../../assets/icons/dashboard/vaults/gift.svg";
import { ReactComponent as CaurseUp } from "../../../assets/icons/dashboard/vaults/solar_course-up-bold (1).svg";
import { ReactComponent as UpBoldIcon } from "../../../assets/icons/dashboard/vaults/solar_course-up-bold.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/dashboard/vaults/tabler_activity-heartbeat.svg";
import { ReactComponent as LockIcon } from "../../../assets/icons/dashboard/vaults/lock.svg";
import { ReactComponent as DeviceIcon } from "../../../assets/icons/dashboard/vaults/heroicons-solid_device-mobile.svg";
import VaultFilter from "./vaultFilter";
import {
  getDevicesPiechartAction,
  getDevicesStatusAction,
  getVaultDeviceAction,
} from "../../../store/vault/actions";
import { useDispatch, useSelector } from "react-redux";
import { CustomChart } from "./customChart";
import CustomPieChart from "./customPieChart";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import AllDeviceTable from "./manage devices/allDeviceTable";
import DeviceDetails from "./manage devices/deviceDetails";

const VaultOverView = () => {
  const { auth, vault, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [serviceDetails, setServiceDetails] = useState("");
  const [viewDetail, setViewDetails] = useState(false);
  const [data, setData] = useState<any>();
  const [limit, setLimit] = React.useState(15);
  const [page, setPage] = React.useState(0);
  const [stateInfo, setStateInfo] = useState("");

  useEffect(() => {
    if (auth?.token) {
      getVaultDeviceAction(
        auth?.token,
        `?serviceDetails=${serviceDetails}&stateInfo=${stateInfo}`
      )(dispatch);
      getDevicesStatusAction(
        auth?.token,
        `?serviceDetails=${serviceDetails}`
      )(dispatch);
      getDevicesPiechartAction(
        auth?.token,
        `?groupByField=${"deviceManufacturer"}&serviceDetails=${serviceDetails}`
      )(dispatch);
    }
  }, [auth.token, dispatch, limit, page, serviceDetails, stateInfo]);

  const pichartData = vault?.piechart;
  return (
    <>
      {!viewDetail ? (
        <>
          <div>
            <VaultFilter
              setServiceDetails={setServiceDetails}
              setStateInfo={setStateInfo}
            />
          </div>
          <div className="flex flex-wrap justify-between gap-4 w-full pr-4">
            <div className="w-[51rem] mt-3 ">
              <div className="flex flex-wrap gap-2">
                <Card
                  name={"Total Devices"}
                  total={
                    vault?.isFetching ? (
                      <Spin indicator={<LoadingOutlined spin />} size="small" />
                    ) : vault?.statusCount?.total ? (
                      vault?.statusCount?.total
                    ) : (
                      0
                    )
                  }
                  customeIcon={<DeviceIcon />}
                  icon={<CaurseUp />}
                  percent={"20%"}
                />
                <Card
                  name={"Idle"}
                  total={
                    vault?.isFetching ? (
                      <Spin indicator={<LoadingOutlined spin />} size="small" />
                    ) : vault?.statusCount?.data?.some((i: any) =>
                      i.hasOwnProperty("Idle")
                    ) ? (
                      vault?.statusCount?.data?.find((i: any) =>
                        i.hasOwnProperty("Idle")
                      )["Idle"]
                    ) : (
                      0
                    )
                  }
                  customeIcon={<VectorIcon />}
                  percent={"-10%"}
                  icon={<UpBoldIcon className="mt-[2px]" />}
                />
                <Card
                  name={"Ready for use"}
                  total={
                    vault?.isFetching ? (
                      <Spin indicator={<LoadingOutlined spin />} size="small" />
                    ) : vault?.statusCount?.data?.some((i: any) =>
                      i.hasOwnProperty("Ready For Use")
                    ) ? (
                      vault?.statusCount?.data?.find((i: any) =>
                        i.hasOwnProperty("Ready For Use")
                      )["Ready For Use"]
                    ) : (
                      0
                    )
                  }
                  customeIcon={<GiftIcon />}
                  percent={"10%"}
                  icon={<CaurseUp className="mt-[2px]" />}
                />
                <Card
                  name={"Active"}
                  total={
                    vault?.isFetching ? (
                      <Spin indicator={<LoadingOutlined spin />} size="small" />
                    ) : vault?.statusCount?.data?.some((i: any) =>
                      i.hasOwnProperty("Active")
                    ) ? (
                      vault?.statusCount?.data?.find((i: any) =>
                        i.hasOwnProperty("Active")
                      )["Active"]
                    ) : (
                      0
                    )
                  }
                  customeIcon={<PhoneIcon />}
                  percent={"-10%"}
                  icon={<DownIcon className="mt-[2px]" />}
                />
                <Card
                  name={"Locked"}
                  total={
                    vault?.isFetching ? (
                      <Spin indicator={<LoadingOutlined spin />} size="small" />
                    ) : vault?.statusCount?.data?.some((i: any) =>
                      i.hasOwnProperty("Locked")
                    ) ? (
                      vault?.statusCount?.data?.find((i: any) =>
                        i.hasOwnProperty("Locked")
                      )["Locked"]
                    ) : (
                      0
                    )
                  }
                  customeIcon={<LockIcon />}
                  percent={"-10%"}
                  icon={<DownIcon className="mt-[2px]" />}
                />
                <Card
                  name={"Released"}
                  total={
                    vault?.isFetching ? (
                      <Spin indicator={<LoadingOutlined spin />} size="small" />
                    ) : vault?.statusCount?.data?.some((i: any) =>
                      i.hasOwnProperty("Released")
                    ) ? (
                      vault?.statusCount?.data?.find((i: any) =>
                        i.hasOwnProperty("Released")
                      )["Released"]
                    ) : (
                      0
                    )
                  }
                  customeIcon={<TravelIcon />}
                  percent={"-10%"}
                  icon={<DownIcon className="mt-[2px]" />}
                />
              </div>
            </div>
            <div
              className={`${layout?.isSideNavOpen ? "w-[30rem]" : "w-[37rem]"
                } bg-white h-[15rem]`}
            >
              <CustomPieChart
                data={pichartData}
                isLoading={vault?.isFetching}
              />
            </div>
          </div>
          <div className="mt-5 ">
            {" "}
            <CustomChart />
          </div>
          <div className="mt-8">
            <AllDeviceTable setData={setData} setViewDetails={setViewDetails} />
          </div>
        </>
      ) : (
        <DeviceDetails
          viewDetail={viewDetail}
          setViewDetails={setViewDetails}
          deviceData={data}
        />
      )}
    </>
  );
};
export default VaultOverView;
