import { Col, Row, Spin } from "antd";
import React, { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVaultCustomerAction } from "../../../../store/vault/actions";

const CustomerInfos = (props: any) => {
  const { auth, vault } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    auth?.token && getVaultCustomerAction(auth?.token, props?.deviceData?.imei)(dispatch);
  }, [auth?.token, dispatch, props?.deviceData?.imei]);
  return (
    <div className="w-[80%]">
      {vault?.isFetching ? (
        <div className="w-full pl-32 pt-40">
          <Spin size="large" />
        </div>
      ) : (
        <>
          {vault?.customer?.data?.map((el: any) => (
            <>
              <Row className="border-t-[1px] border-l-[1px] border-r-[1px] border-[#030229] border-opacity-5 rounded-md ">
                <Col
                  span={12}
                  className="bg-[#F5F6FA]  p-4 text-[15px] text-[#605BFF] font-normal px-3"
                >
                  Names
                </Col>
                <Col
                  span={12}
                  className="bg-white text-[15px] text-[#030229] font-normal p-4 px-3"
                >
                  {el?.name}
                </Col>
              </Row>
              <Row className="border-t-[1px] border-l-[1px] border-r-[1px] border-[#030229] border-opacity-5 rounded-md">
                <Col
                  span={12}
                  className="bg-[#F5F6FA]  p-4 text-[15px] text-[#605BFF] font-normal px-3"
                >
                  Email
                </Col>
                <Col
                  span={12}
                  className="bg-white text-[15px] text-[#030229] font-normal p-4 capitalize px-3"
                >
                  {el?.email}
                </Col>
              </Row>
              <Row className="border-t-[1px] border-l-[1px] border-r-[1px] border-[#030229]  border-opacity-5 rounded-md">
                <Col
                  span={12}
                  className="bg-[#F5F6FA]  p-4 text-[15px] text-[#605BFF] font-normal px-3"
                >
                  Phone Number
                </Col>
                <Col
                  span={12}
                  className="bg-white text-[15px] text-[#030229] font-normal p-4 capitalize px-3"
                >
                  {el?.phone}
                </Col>
              </Row>
              <Row className="border-t-[1px] border-l-[1px] border-r-[1px] border-[#030229]  border-opacity-5 rounded-md">
                <Col
                  span={12}
                  className="bg-[#F5F6FA]  p-4 text-[15px] text-[#605BFF] font-normal px-3"
                >
                  National ID
                </Col>
                <Col
                  span={12}
                  className="bg-white text-[15px] text-[#030229] font-normal p-4 capitalize px-3"
                >
                  {el?.nid}
                </Col>
              </Row>
              <Row className="border-t-[1px] border-l-[1px] border-r-[1px] border-[#030229]  border-opacity-5 rounded-md">
                <Col
                  span={12}
                  className="bg-[#F5F6FA]  p-4 text-[15px] text-[#605BFF] font-normal px-3"
                >
                  Address
                </Col>
                <Col
                  span={12}
                  className="bg-white text-[15px] text-[#030229] font-normal p-4 capitalize px-3"
                >
                  {el?.address}
                </Col>
              </Row>
              <Row className="border-t-[1px] border-l-[1px] border-r-[1px] border-[#030229]  border-opacity-5 rounded-md">
                <Col
                  span={12}
                  className="bg-[#F5F6FA]  p-4 text-[15px] text-[#605BFF] font-normal px-3"
                >
                  Current Status
                </Col>
                <Col
                  span={12}
                  className="bg-white text-[15px] text-[#030229] font-normal p-4 capitalize px-3"
                >
                  <button className="rounded-lg px-6 py-2 font-medium bg-[#BCF4BC] text-white text-sm">
                    {el?.status}
                  </button>
                </Col>
              </Row>
            </>
          ))}
        </>
      )}
    </div>
  );
};
export default CustomerInfos;
