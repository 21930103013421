import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVaultDeviceAction } from "../../../../store/vault/actions";
import {
  Table,
  TableProps,
  DatePicker,
  Button,
  TimeRangePickerProps,
} from "antd";
import { PiEyeThin } from "react-icons/pi";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import moment from "moment";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";

interface DataType {
  key: string;
  imei: string;
  service: any;
  status: string;
  brand: string;
  model: string;
  lastchange: string;
  lastcheckin: string;
}
const { RangePicker } = DatePicker;

const AllDeviceTable = (props: any) => {
  const { auth, vault } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState<string>("");
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const [selectedBrand, setSelectedBrand] = useState("");
  let today = moment();
  const formattedDate = new Date().toISOString().slice(0, 10);
  let previousDate = today.subtract(7, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [startDate, setStartDate] = React.useState<any>(formattedPreviousDate);
  const [endDate, setEndDate] = React.useState<any>(formattedDate);

  const handleFilter = () => {
    if (searchKey && auth?.token) {
      getVaultDeviceAction(
        auth?.token,
        `?imei=${searchKey}&brand=${selectedBrand}`
      )(dispatch);
    } else {
      if (auth?.token && searchKey === "") {
        getVaultDeviceAction(
          auth?.token,
          `?limit=${limit}&page=${page - 1}`
        )(dispatch);
        setIsFiltered(true);
      }
    }
    setIsFiltered(true);
  };
  useEffect(() => {
    if (auth?.token) {
      getVaultDeviceAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}`
      )(dispatch);
    }
  }, [page, limit, auth?.token, dispatch]);
  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setStartDate(dateStrings[0]);
      setEndDate(dateStrings[1]);
    } else {
      console.log("Clear");
    }
  };
  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Imei",
      dataIndex: "imei",
      key: "imei",
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
    },
    {
      title: "Current Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Last Change",
      dataIndex: "lastchange",
      key: "lastchange",
    },
    {
      title: "Last Checkin",
      dataIndex: "lastcheckin",
      key: "lastcheckin",
    },
    {
      title: "Action",
      key: "data",
      render: (record: any) => (
        <PiEyeThin
          size={20}
          color="#030229"
          onClick={() => {
            props?.setData(record);
            props?.setViewDetails(true);
            return {};
          }}
        />
      ),
    },
  ];
  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  ];
  const data = vault?.allDevice?.data?.map((el: any, index: any) => {
    return {
      key: el?.index,
      imei: el?.deviceUniqueId,
      service: el?.serviceDetails,
      status: el?.stateInfo,
      brand: el?.deviceManufacturer?.toUpperCase(),
      model: el.deviceModel,
      lastchange: el?.lastChanged?.slice(0, 10),
      lastcheckin: el?.lastCheckIn?.slice(0, 10),
      data: el,
    };
  });
  return (
    <>
      <div>
        <form className="w-full h-fufll bg-white my-4 rounded-md p-4 py-4 sbg-white flex flex-wrap gap-4 text-[#030229]">
          <div>
            <h1 className="font-semibold text-[17px] mb-4">IMEI</h1>
            <input
              type="text"
              id="large-input"
              className=" w-[25rem] p-3 bg-white border border-[#605BFF] border-opacity-25 text-[#030229] opacity-60 text-sm rounded-lg block"
              placeholder="0000000000"
              onChange={(e: any) => setSearchKey(e?.target?.value)}
            />
          </div>
          <div>
            <h1 className="font-semibold text-[17px] mb-4">Brand</h1>
            <select
              id="large"
              className="bg-white p-[.73rem] border border-[#605BFF] border-opacity-25 text-[#030229] opacity-60 text-sm rounded-lg block w-[25rem]"
              onChange={(e: any) => {
                const value = e.target.value;
                !value && setSelectedBrand("");
                value && setSelectedBrand(value);
              }}
            >
              {vault?.allDevice?.data?.map((d: any, index: any) => (
                <option
                  key={index}
                  value={d?.deviceMarketName}
                  className="capitalize"
                >
                  {d?.deviceMarketName}
                </option>
              ))}
            </select>
          </div>
          <div>
            <h1 className="font-semibold text-[17px] mb-4">Last change</h1>
            <RangePicker
              presets={rangePresets}
              onChange={onRangeChange}
              className="bg-white border border-[#605BFF] border-opacity-25 text-[#030229] opacity-60 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex w-[25rem] p-[.73rem]"
            />
          </div>
          <div className="mt-[2.6rem]">
            <Button
              onClick={() => handleFilter()}
              style={{
                backgroundColor: "#605BFF",
                width: "130px",
                height: "44px",
                fontSize: "16px",
                color: "white",
              }}
            >
              Filter
            </Button>
          </div>
        </form>
      </div>
      {isFiltered && (
        <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={vault?.allDevice?.total}
          count={Math.ceil(vault?.allDevice?.total / limit)}
        >
          <Table<DataType>
            columns={columns}
            dataSource={data}
            pagination={false}
            loading={vault?.isFetching}
          />
        </ScrollableFrame>
      )}
    </>
  );
};
export default AllDeviceTable;
