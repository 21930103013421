import { Box, Stack, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Badge, Divider } from "antd";
import { searchValue } from "../../utils/setColor";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "./../../components/Modals/DeleteModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteRequestStockAction } from "../../store/channel/actions";
import { getActiveShop } from "../../utils/converter";
import { getAllStockRequestSentAction } from "../../store/wareHouseOut/actions";

const Component = (props: any) => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth, channel, shop } = useSelector((state: any) => state);
  const data = {
    name: props?.data?.shopId?.name || "New RFS",
    status: props.data?.status,
    id: props.data?.requestId,
    product:
      props.data?.list?.map((item: any) => item?.product?.product?.model) || "",
    quantity: props.data?.list?.map((item: any) => item?.qtyRequested) || "0",
  };

  const shopId =
    shop?.selected?._id ||
    getActiveShop(auth?.user?.shop?.assigned)[0]?.shop?._id;

  const deleteModal = () => {
    setVisible(true);
  };
  const deleteRequest = async () => {
    if (auth?.token) {
      await deleteRequestStockAction(
        auth?.token,
        props?.data?._id,
        `?shopId=${shopId}`
      )(dispatch);
      await getAllStockRequestSentAction(
        auth?.token,
        "?status=review&limit=20&page=0"
      )(dispatch);
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Badge.Ribbon
        text={
          <span
            style={{
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              fontWeight: 400,
              paddingTop: "1.5px",
            }}
            className="capitalize"
          >
            {data.status}
          </span>
        }
        style={{ padding: "4px 10px" }}
        color={searchValue(data.status)}
      >
        <div
          className="min-h-[160px] bg-white p-2 rounded-md hover:border-black relative shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] text-[#030229]"
          onClick={() => {
            navigate(data.id);
          }}
        >
          <div className="pt-2 px-1">
            <div className="text-base space-y-2">
              <h4 className="font-normal text-[14px] leading-3 text-[#030229] font-poppins">
                {data?.name}
              </h4>
              <p className="w-60 text-[13px] font-normal text-blue-400">
                {data?.id}
              </p>
            </div>
          </div>
          <Divider />
          <div className="flex -mt-2 justify-between items-end w-full">
            <div>
              <p className="opacity-80 text-[12px] font-normal leading-normal">
                Requested By:{" "}
                <span className="font-semibold text-xs">
                  {props?.data?.createdBy?.names}
                </span>
              </p>

              {props?.data?.status === "done" && (
                <>
                  <p className="opacity-80 text-[12px] font-normal leading-normal">
                    Approved By:{" "}
                    <span className="font-semibold text-xs">
                      {props?.data?.approvedBy?.names}
                    </span>
                  </p>
                  <p className="opacity-80 text-[12px] font-normal leading-normal">
                    Approved On:{" "}
                    <span className="font-semibold text-xs">
                      {props?.data?.updatedAt?.split("T")[0]}
                    </span>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        {["request", "review"].includes(props?.data?.status) && (
          <Stack
            direction="row"
            alignItems="flex-end"
            spacing={0}
            className="absolute right-2 bottom-2"
          >
            <IconButton aria-label="delete" size="small">
              <DeleteIcon fontSize="inherit" onClick={deleteModal} />
            </IconButton>
          </Stack>
        )}
      </Badge.Ribbon>

      <DeleteModal
        visible={visible}
        onOk={deleteRequest}
        isLoading={channel.isFetching}
        onCancel={handleCancel}
        itemName="Stock Request"
      />
    </>
  );
};

export default Component;
