import React, { useState, useEffect } from "react";
import {
  Button,
  Carousel,
  Col,
  Divider,
  Drawer,
  Modal,
  Popconfirm,
  Row,
  Space,
} from "antd";
import { getDefaultCurrencyCode } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import defaultPic from "../../assets/nophoto.png";
import { AiFillCheckCircle } from "react-icons/ai";
import {
  acceptSubRequestAction,
  getAllSubRequestedAction,
} from "../../store/subscription/subscriptionRequest/actions";
import RejectSubscriptionReqForm from "../forms/RejectSubscriptionReqForm";
import {
  getOneCustomerAction,
  updateCustomerAction,
} from "../../store/customer/actions";
import CancelSubRequest from "../forms/CancelSubscriptionRequestform";
import { BsCheckSquare } from "react-icons/bs";
import { BsXSquare } from "react-icons/bs";

const SubscriptionDrawer: React.FC<{
  open: boolean;
  onClose: any;
  data: any;
}> = ({ open, onClose, data }) => {
  const { company, auth, subscriptionRequest } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const [openModel, setOpenModel] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [reasonModal, setReasonModal] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [currentGroupName, setCurrentGroupName] = useState("");
  const [confirmations, setConfirmations] = useState(
    data?.data?.customer?.confirmation || []
  );

  const showReasonModal = (groupName: string) => {
    setReasonModal(true);
    setCurrentGroupName(groupName);
  };
  const cancelReason = () => {
    setReasonModal(false);
    setCurrentGroupName("");
  };
  const handleReasonSubmit = (groupName: string, reason: string) => {
    setSelectedReason(reason);
    handleConfirmation(groupName, false, reason);
    setReasonModal(false);
  };

  const showModal = (value: any) => {
    setOpenModel(true);
    setRequestId(value);
  };

  const cancelModal = () => {
    setOpenModel(false);
    setRequestId("");
  };
  useEffect(() => {
    setConfirmations(data?.data?.customer?.confirmation);
  }, [data?.data?.customer?.confirmation]);
  const handleConfirmation = async (
    groupName: string,
    isAccepted: boolean,
    reason: string
  ) => {
    const updatedConfirmations = JSON.parse(JSON.stringify(confirmations))?.map(
      (item: { groupName: string }) => {
        if (item.groupName === groupName) {
          return { ...item, isAccepted, reason };
        }
        return item;
      }
    );

    if (
      !updatedConfirmations?.some(
        (item: { groupName: string }) => item.groupName === groupName
      )
    ) {
      updatedConfirmations?.push({ groupName, isAccepted, reason });
    }

    await updateCustomerAction(auth?.token, data?.data?.customer?._id, {
      confirmation: updatedConfirmations,
    })(dispatch);

    setConfirmations(updatedConfirmations);

    await getOneCustomerAction(
      auth?.token,
      data?.data?.customer?._id
    )(dispatch);
    getAllSubRequestedAction(auth?.token, "?")(dispatch);
  };


  const handleAccept = async () => {
    await acceptSubRequestAction(auth?.token, data?.data?._id, {
      status: "accepted",
    })(dispatch);
    onClose();
  };
  const isAnyRejected = confirmations?.some((confirmation: any) => confirmation.isAccepted === false);

  const FinancialDetails = [
    {
      label: "Main Source Of Income",
      value: `${data?.data?.customer?.subscriptionInfo?.mainIncomeSource || "N/A"
        }`,
    },
    {
      label: "Occupation",
      value: `${data?.data?.customer?.subscriptionInfo?.occupationOrBusinessType ||
        "N/A"
        }`,
    },
    {
      label: "Monthly Income",
      value: `${data?.data?.customer?.subscriptionInfo?.monthlyIncome || "N/A"
        }`,
    },
    {
      label: "Income Frequency",
      value: `${data?.data?.customer?.subscriptionInfo?.incomeFrequency || "N/A"
        }`,
    },
    {
      label: "Do you currently own a Smartphone",
      value: `${data?.data?.customer?.subscriptionInfo?.ownsSmartphone || "N/A"
        }`,
    },
  ];
  const requestDetalis = [
    {
      label: "Shop",
      value: `${data?.data?.shop?.name}`,
    },
    {
      label: "Team Lead",
      value: `${data?.data?.createdBy?.names}`,
    },
    {
      label: "Freelancer",
      value: `${data?.data?.createdBy?.names}`,
    },
    {
      label: "Device",
      value: `${data?.data?.selectedPlan?.pricelistItem?.product?.model}`,
    },
  ];
  const adressDetails = [
    {
      label: "Country",
      value: `${data?.data?.customer?.subscriptionInfo?.address?.country}`,
    },
    {
      label: "Province",
      value: `${data?.data?.customer?.subscriptionInfo?.address?.province}`,
    },
    {
      label: "District",
      value: `${data?.data?.customer?.subscriptionInfo?.address?.district}`,
    },
    {
      label: "Sector",
      value: `${data?.data?.customer?.subscriptionInfo?.address?.sector}`,
    },
    {
      label: "Cell",
      value: `${data?.data?.customer?.subscriptionInfo?.address?.cell}`,
    },
    {
      label: "Village",
      value: `${data?.data?.customer?.subscriptionInfo?.address?.village}`,
    },
  ];
  return (
    <Drawer
      title={
        <span
          style={{
            fontWeight: 500,
            color: "#030229",
            fontSize: "18px",
            marginLeft: "13px",
          }}
        >
          Request Details
        </span>
      }
      width={800}
      placement="right"
      closable={false}
      onClose={onClose}
      open={open}
      style={{ color: "[#030229]" }}
    >
      <div className="text-[#030229] p-2 opacity-95">
        <div className="border-[1px] border-[#03022910] flex justify-between p-6 rounded-lg relative">
          <div className="flex gap-3 absolute right-4 top-4">
            <BsXSquare
              size={24}
              className={`text-red-500 ${confirmations?.find(
                (item: { groupName: string }) =>
                  item.groupName === "Customer Details"
              )?.isAccepted === false
                ? "bg-red-300"
                : ""
                }`}
              onClick={() => showReasonModal("Customer Details")}
            />
            <BsCheckSquare
              size={24}
              className={`text-green-400 ${confirmations?.find(
                (item: { groupName: string }) =>
                  item.groupName === "Customer Details"
              )?.isAccepted === true
                ? "bg-[#3AC722]"
                : ""
                }`}
              onClick={async () =>
                handleConfirmation("Customer Details", true, "")
              }
            />
          </div>
          <div className="w-[320px] h-[200px]">
            <h1 className="font-medium text-[16px]  mb-4 ">Customer Details</h1>
            <div className="flex gap-4">
              <img
                className="inline-block h-[4rem] w-[5rem] rounded-full ring-2 ring-white"
                src={data?.data?.customer?.picture}
                alt=""
              />
              <div className="font-normal ">
                <h1 className="text-[16px] mb-3">
                  {data?.data?.customer?.name}
                </h1>
                <div className=" flex flex-col gap-2 opacity-70 text-[14px] mt-2">
                  <p>{data?.data?.customer?.nationalId}</p>
                  <p>{data?.data?.customer?.email}</p>
                  <Space>
                    {data?.data?.customer?.phone} <Divider type="vertical" />
                    {data?.data?.customer?.otherPhone?.map(
                      (el: any, index: number) => (
                        <p key={index}>{el?.tel}</p>
                      )
                    )}
                  </Space>{" "}
                  <button className="bg-[#D5D9DD] w-[97px] h-[32px] hover:bg-[#D5D9DD70] text-black font-medium   rounded-[16px] text-[10px] mt-2">
                    {data?.data?.status}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="text-[14px] w-[280px] h-[200px]">
            <h1 className="font-medium text-[16px]  mb-4">Address</h1>
            <div className="flex flex-col gap-2">
              {adressDetails?.map((el: any) => (
                <Row>
                  <Col style={{ opacity: 0.6, fontSize: "13px " }} span={4}>
                    {el?.label}
                  </Col>
                  <Col style={{ fontSize: "13px " }} span={12} offset={4}>
                    {el?.value}
                  </Col>
                </Row>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-8">
          <div className="border-[1px] border-[#03022910] p-3 rounded-lg w-[390px] h-[220px] relative">
            <div className="flex gap-3 absolute right-4 top-4">
              <BsXSquare
                size={24}
                className={`text-red-500 ${confirmations?.find(
                  (item: { groupName: string }) =>
                    item.groupName === "Documents"
                )?.isAccepted === false
                  ? "bg-red-300"
                  : ""
                  }`}
                onClick={() => showReasonModal("Documents")}
              />
              <BsCheckSquare
                size={24}
                className={`text-green-400 ${confirmations?.find(
                  (item: { groupName: string }) =>
                    item.groupName === "Documents"
                )?.isAccepted === true
                  ? "bg-[#3AC722]"
                  : ""
                  }`}
                onClick={async () =>
                  handleConfirmation("Documents", true, "")
                }
              />
            </div>
            <h1 className="font-medium text-[16px]  mb-2">Documents</h1>
            <div className="flex justify-between">
              <div>
                <p className="mb-2 opacity-80">National ID</p>
                <div className="w-[180px] h-[110px] bg-[#F4F6FA] rounded-[10px] border-[.1px] border-[#D9DBE9] border-opacity-20">
                  <img
                    src={data?.data?.customer?.subscriptionInfo?.nationalIDDoc}
                    width={"100%"}
                    alt="nid"
                  />
                </div>
              </div>
              <div>
                <p className="mb-2 opacity-80">Picture</p>
                <div className="w-[180px] h-[110px] bg-[#F4F6FA] rounded-[10px] border-[.1px] border-[#D9DBE9] border-opacity-20">
                  <img
                    src={
                      data?.data?.customer?.subscriptionInfo
                        ?.photoCustomerHoldingID
                    }
                    width={"100%"}
                    alt="nid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="border-[1px] border-[#03022910] p-6 rounded-lg w-[310px] h-[220px] relative">
            <div className="flex gap-3 absolute right-4 top-4">
              <BsXSquare
                size={24}
                className={`text-red-500 ${confirmations?.find(
                  (item: { groupName: string }) =>
                    item.groupName === "Witness Details"
                )?.isAccepted === false
                  ? "bg-red-300"
                  : ""
                  }`}
                onClick={() => showReasonModal("Witness Details")}
              />
              <BsCheckSquare
                size={24}
                className={`text-green-400 ${confirmations?.find(
                  (item: { groupName: string }) =>
                    item.groupName === "Witness Details"
                )?.isAccepted === true
                  ? "bg-[#3AC722]"
                  : ""
                  }`}
                onClick={async () =>
                  handleConfirmation("Witness Details", true, "")
                }
              />
            </div>
            <h1 className="font-medium text-[16px]  mb-4">Witness Details</h1>
            {data?.data?.customer?.subscriptionInfo?.witnessInfo?.map(
              (d: any) => (
                <div className="flex flex-col gap-3 text-[14px]">
                  <Row>
                    <Col style={{ opacity: 0.6, fontSize: "13px " }} span={6}>
                      Name :{" "}
                    </Col>
                    <Col style={{ fontSize: "13px " }} span={8} offset={4}>
                      {d?.name}
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ opacity: 0.6, fontSize: "13px " }} span={6}>
                      Phone :{" "}
                    </Col>
                    <Col style={{ fontSize: "13px " }} span={8} offset={4}>
                      {d?.phone}
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ opacity: 0.6, fontSize: "13px " }} span={8}>
                      Relationship :{" "}
                    </Col>
                    <Col style={{ fontSize: "13px " }} span={8} offset={2}>
                      {d?.relationship}
                    </Col>
                  </Row>
                </div>
              )
            )}
          </div>
        </div>
        <div className="border-[1px] border-[#03022910] p-6 rounded-lg mt-8 relative">
          <div className="flex gap-3 absolute right-4 top-4">
            <div className="flex gap-3 absolute right-4 top-4">
              <BsXSquare
                size={24}
                className={`text-red-500 ${confirmations?.find(
                  (item: { groupName: string }) =>
                    item.groupName === "Financial Details"
                )?.isAccepted === false
                  ? "bg-red-300"
                  : ""
                  }`}
                onClick={() => showReasonModal("Financial Details")}
              />
              <BsCheckSquare
                size={24}
                className={`text-green-400 ${confirmations?.find(
                  (item: { groupName: string }) =>
                    item.groupName === "Financial Details"
                )?.isAccepted === true
                  ? "bg-[#3AC722]"
                  : ""
                  }`}
                onClick={async () =>
                  handleConfirmation("Financial Details", true, "")
                }
              />
            </div>
          </div>
          <h1 className="font-medium text-[16px]  mb-4">Financial Details</h1>
          <div className="flex flex-col gap-3 text-[14px]">
            {FinancialDetails?.map((el: any) => (
              <Row>
                <Col style={{ opacity: 0.6, fontSize: "13px " }} span={12}>
                  {el?.label} :{" "}
                </Col>
                <Col style={{ fontSize: "13px " }} span={12}>
                  {el?.value}
                </Col>
              </Row>
            ))}
          </div>
        </div>
        <div className="border-[1px] border-[#03022910] p-6 rounded-lg mt-8 relative">
          <div className="flex gap-3 absolute right-4 top-4">
            <BsXSquare
              size={24}
              className={`text-red-500 ${confirmations?.find(
                (item: { groupName: string }) =>
                  item.groupName === "Request Details"
              )?.isAccepted === false
                ? "bg-red-300"
                : ""
                }`}
              onClick={() => showReasonModal("Request Details")}
            />
            <BsCheckSquare
              size={24}
              className={`text-green-400 ${confirmations?.find(
                (item: { groupName: string }) =>
                  item.groupName === "Request Details"
              )?.isAccepted === true
                ? "bg-[#3AC722]"
                : ""
                }`}
              onClick={async () =>
                handleConfirmation("Request Details", true, "")
              }
            />
          </div>
          <h1 className="font-medium text-[16px]  mb-4">Request Details</h1>
          <div className="flex flex-col gap-2 text-[14px]">
            {requestDetalis?.map((el: any) => (
              <Row>
                <Col style={{ opacity: 0.6, fontSize: "13px " }} span={4}>
                  {el?.label} :
                </Col>
                <Col style={{ fontSize: "13px " }} span={12} offset={2}>
                  {el?.value}
                </Col>
              </Row>
            ))}
          </div>
        </div>
        <div className="border-[1px] border-[#03022910] p-6 rounded-lg mt-8 relative">
          <div className="flex gap-3 absolute right-4 top-4">
            <BsXSquare
              size={24}
              className={`text-red-500 ${confirmations?.find(
                (item: { groupName: string }) =>
                  item.groupName === "Plan Details"
              )?.isAccepted === false
                ? "bg-red-300"
                : ""
                }`}
              onClick={() => showReasonModal("Plan Details")}
            />
            <BsCheckSquare
              size={24}
              className={`text-green-400 ${confirmations?.find(
                (item: { groupName: string }) =>
                  item.groupName === "Plan Details"
              )?.isAccepted === true
                ? "bg-[#3AC722]"
                : ""
                }`}
              onClick={async () =>
                handleConfirmation("Plan Details", true, "")
              }
            />
          </div>
          <h1 className="font-medium text-[16px]  mb-4">Plan Details</h1>
          <div className="flex gap-6">
            <div className="w-[98px] h-[110px] bg-[#F4F6FA] rounded-[10px] p-3 border-[.1px] border-[#D9DBE9] border-opacity-20">
              {data?.data?.selectedPlan?.pricelistItem?.product?.images
                ?.length === 0 && (
                  <img src={defaultPic} width={"100%"} alt="" />
                )}
              {data?.data?.selectedPlan?.pricelistItem?.product?.images > 0 && (
                <Carousel autoplay>
                  {data?.data?.selectedPlan?.pricelistItem?.product?.images?.map(
                    (im: any) => (
                      <div className="block w-[59px] h-[87px]">
                        <img
                          src={im}
                          alt=""
                          className="w-full h-full object-cover"
                        />
                      </div>
                    )
                  )}
                </Carousel>
              )}
            </div>
            <div className="flex flex-col">
              <h1 className="text-sm font-medium mb-2">
                {data?.data?.selectedPlan?.pricelistItem?.product?.model}
              </h1>
              <Space>
                <p className=" opacity-60">
                  Brand :{" "}
                  {data?.data?.selectedPlan?.pricelistItem?.product?.brand}
                </p>
                <p></p>
              </Space>
              <Space>
                <p className=" opacity-60">
                  Capacity{" "}
                  {data?.data?.selectedPlan?.pricelistItem?.specification?.at(
                    3
                  )}{" "}
                </p>
                <p></p>
              </Space>
              <Space>
                <p className=" opacity-60">
                  Memory{" "}
                  {data?.data?.selectedPlan?.pricelistItem?.specification?.at(
                    2
                  )}
                </p>
                <p></p>
              </Space>
            </div>
          </div>
          <div className="flex justify-between mt-4">
            <div className="flex flex-col gap-2">
              <h3 className="font-medium mb-2">Allowance : </h3>
              <Space>
                <AiFillCheckCircle color="#605BFF90" />
                <p className=" opacity-60">
                  Bundles : {data?.data?.selectedPlan?.bundle?.at(0)} / Per
                  Month
                </p>
                <p></p>
              </Space>
              <Space>
                <AiFillCheckCircle color="#605BFF90" />
                <p className=" opacity-60">
                  SMS : {data?.data?.selectedPlan?.sms?.at(0)} / Per Month
                </p>
                <p></p>
              </Space>
              <Space>
                <AiFillCheckCircle color="#605BFF90" />
                <p className=" opacity-60">
                  Airtime : {data?.data?.selectedPlan?.call?.at(0)} /Per Month
                </p>
                <p></p>
              </Space>
            </div>
            <div className="flex flex-col gap-2">
              <h3 className="font-medium mb-2">Installemnt plan</h3>

              <Space>
                <AiFillCheckCircle color="#605BFF90" />
                <p className=" opacity-60">
                  Upfront : {data?.data?.selectedPlan?.initialPayment}{" "}
                  {getDefaultCurrencyCode(company)}
                </p>
                <p></p>
              </Space>
              <Space>
                <AiFillCheckCircle color="#605BFF90" />
                <p className=" opacity-60">
                  Monthly Payment :{" "}
                  {data?.data?.selectedPlan?.monthlyInstalment}{" "}
                  {getDefaultCurrencyCode(company)}
                </p>
                <p></p>
              </Space>
              <Space>
                <AiFillCheckCircle color="#605BFF90" />
                <p className=" opacity-60">
                  Duration : {data?.data?.selectedPlan?.duration} Months
                </p>
              </Space>
            </div>
          </div>
        </div>
        <div className=" mt-4 float-right pb-6">
          {!["accepted", "rejected", "paid"]?.includes(data?.data?.status) && (
            <div className="flex items-center space-x-1 cursor-pointer">
              <Button
                className="capitalize min-w-[50px] text-sm text-center outline-none font-normal"
                danger
                onClick={() => showModal(data?.data?.status)}
              >
                Reject
              </Button>
              <Popconfirm
                title="Accept Request"
                description="Are you sure to Accept this Subscription Request?"
                onConfirm={handleAccept}
                okText="Confirm"
                cancelText="Cancel"
                placement="top"
                okButtonProps={{
                  loading: subscriptionRequest?.isFetching,
                  className: "bg-blue-500",
                }}
              >
                <Button
                  className="capitalize min-w-[50px] bg-[#20BF55] font-normal text-sm text-white text-center border-none outline-none"
                  disabled={isAnyRejected}
                >
                  Accept
                </Button>
              </Popconfirm>
            </div>
          )}
        </div>
      </div>
      <Modal
        open={openModel}
        onCancel={cancelModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <RejectSubscriptionReqForm
          onCancel={cancelModal}
          requestId={data?.data?._id}
          accountId={data?.data?.account?._id}
        />
      </Modal>
      <Modal
        title="Enter the Reason"
        open={reasonModal}
        onCancel={cancelReason}
        footer={null}
      >
        <CancelSubRequest
          onCancel={cancelReason}
          onReasonSubmit={(reason: string) =>
            handleReasonSubmit(currentGroupName, reason)
          }
          data={data?.data?.customer}
        />
      </Modal>
    </Drawer>
  );
};
export default SubscriptionDrawer;
