import React, { useState, useEffect } from "react";
import { VaultData } from "../../../assets/data/vaults";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {
  getActiveVault,
  storeActiveVault,
  storeSelectedSubVault,
} from "../../../utils/helper";
import "../../../components/layout/sideNav.css";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {},
}));

interface vaultProps {
  activeTabs?: any;
  setActiveTabs?: any;
}

const SideBar = ({ activeTabs, setActiveTabs }: vaultProps) => {
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [selectedTabIndex, setSelectedTabIndex] = useState(getActiveVault());
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const dispatch = useDispatch();
  const smallScreen = useMediaQuery("(max-width:767px)");

  const { auth, vault } = useSelector((state: any) => state);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const styles = {
    border: {
      border: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    },
    shadow: {
      boxShadow: "0px 2px 4px 0px rgba(3, 2, 41, 0.10)",
    },
  };

  const postpaidTotal = vault?.all?.data?.filter(
    (device: any) => device.serviceDetails.toUpperCase() === "POSTPAID"
  ).length;

  const prepaidTotal = vault?.all?.data?.filter(
    (device: any) => device.serviceDetails.toUpperCase() === "PREPAID"
  ).length;

  return (
    <>
      <div
        className="w-[334px] mt-2 fixed bg-white pl-6 pt-6 h-[calc(100%-40px)]"
        style={styles.shadow}
      >
        <ul className="font-medium text-base text-[#030229]">
          {VaultData.map((vault: any, index: number) => {
            return (
              <li
                className="flex py-1 gap-2"
                key={vault.id}
                onClick={() => {
                  storeActiveVault(vault.id);
                  setSelectedTabIndex(vault.id);
                  localStorage.setItem("activeSideVault", vault.id);
                }}
              >
                {vault.dropDown ? (
                  <Accordion
                    expanded={expanded === vault.id}
                    onChange={handleChange(vault.id)}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      className="w-[270px]"
                    >
                      <Typography
                        className={`flex gap-2 text-[#030229] text-base`}
                      >
                        <span
                          className={`text-lg pt-1 pr-4  ${
                            selectedTabIndex === vault.id
                              ? "fill-[#605BFF] vaultIcon"
                              : "#0F0F47"
                          }`}
                        >
                          {vault.icon}
                        </span>{" "}
                        <span
                          className={`pt-1 ${
                            selectedTabIndex === vault?.id
                              ? "text-[#605BFF]"
                              : "text-[#030229]"
                          }`}
                        >
                          {vault.title}
                        </span>{" "}
                      </Typography>
                    </AccordionSummary>
                    <MuiAccordionDetails>
                      <Typography className="pl-1">
                        <ul className="pl-6">
                          {vault.dropDown.map((item: any) => {
                            let count = 0;

                            return (
                              <li
                                key={item?.menu}
                                className={`text-[#030229B2] cursor-pointer list-disc font-medium text-base py-3 relative  ${
                                  localStorage.getItem("selectedSubVault") ===
                                  item.menu
                                    ? "bg-[#F5F6FA] rounded-md pl-1"
                                    : ""
                                }`}
                                onClick={() => {
                                  const selectedSubVault = item.menu;
                                  storeSelectedSubVault(selectedSubVault);
                                  setActiveTabs(item.activeTab);
                                  localStorage.setItem(
                                    "activeNav",
                                    item.activeTab
                                  );
                                }}
                              >
                                <span>{item.menu}</span>{" "}
                              </li>
                            );
                          })}
                        </ul>
                      </Typography>
                    </MuiAccordionDetails>
                  </Accordion>
                ) : (
                  <div
                    className="flex gap-2 ml-4 cursor-pointer"
                    onClick={() => setActiveTabs(vault.activeTab)}
                  >
                    <span
                      className={`text-lg pt-2 pr-4  ${
                        selectedTabIndex === vault.id
                          ? "fill-[#605BFF] vaultIcon"
                          : "#0F0F47"
                      }`}
                    >
                      {vault.icon}
                    </span>{" "}
                    <span
                      className={`pt-2 ${
                        selectedTabIndex === vault.id
                          ? "text-[#605BFF]"
                          : "text-[#030229]"
                      }`}
                    >
                      {vault.title}
                    </span>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};
export default SideBar;
