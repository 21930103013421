import { Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getnotesAction } from "../../../../store/vault/actions";

const DeviceInfos = (props: any) => {
  const { auth, vault } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);

  useEffect(() => {
    auth?.token &&
      getnotesAction(
        auth?.token,
        `?serialNumber=${props?.data?.deviceUniqueID}&limit=${limit}&page=${page - 1
        }`
      )(dispatch);
  }, [auth?.token, dispatch, page, limit, props?.data?.deviceUniqueID]);

  return (
    <div className="w-[80%]">
      {vault?.isFetching ? (<div className="w-full pl-32 pt-40">
        <Spin size="large" />
      </div>) : (
        <>
          <Row className="border-t-[1px] border-l-[1px] border-r-[1px] border-[#030229] border-opacity-5 rounded-md ">
            <Col
              span={12}
              className="bg-[#F5F6FA]  p-4 text-[15px] text-[#605BFF] font-normal px-3"
            >
              Brand
            </Col>
            <Col
              span={12}
              className="bg-white text-[15px] text-[#030229] font-normal p-4 px-3"
            >
              {props?.data?.brand?.toUpperCase()}
            </Col>
          </Row>
          <Row className="border-t-[1px] border-l-[1px] border-r-[1px] border-[#030229] border-opacity-5 rounded-md">
            <Col
              span={12}
              className="bg-[#F5F6FA]  p-4 text-[15px] text-[#605BFF] font-normal px-3"
            >
              Model
            </Col>
            <Col
              span={12}
              className="bg-white text-[15px] text-[#030229] font-normal p-4 capitalize px-3"
            >
              {props?.data?.model}
            </Col>
          </Row>
          <Row className="border-t-[1px] border-l-[1px] border-r-[1px] border-[#030229]  border-opacity-5 rounded-md">
            <Col
              span={12}
              className="bg-[#F5F6FA]  p-4 text-[15px] text-[#605BFF] font-normal px-3"
            >
              IMEI
            </Col>
            <Col
              span={12}
              className="bg-white text-[15px] text-[#030229] font-normal p-4 capitalize px-3"
            >
              {props?.data?.imei}
            </Col>
          </Row>
          <Row className="border-t-[1px] border-l-[1px] border-r-[1px] border-[#030229]  border-opacity-5 rounded-md">
            <Col
              span={12}
              className="bg-[#F5F6FA]  p-4 text-[15px] text-[#605BFF] font-normal px-3"
            >
              Organization Name
            </Col>
            <Col
              span={12}
              className="bg-white text-[15px] text-[#030229] font-normal p-4 capitalize px-3"
            >
              {props?.data?.data?.tenantName}
            </Col>
          </Row>
          <Row className="border-t-[1px] border-l-[1px] border-r-[1px] border-[#030229]  border-opacity-5 rounded-md">
            <Col
              span={12}
              className="bg-[#F5F6FA]  p-4 text-[15px] text-[#605BFF] font-normal px-3"
            >
              Expiration Date
            </Col>
            <Col
              span={12}
              className="bg-white text-[15px] text-[#030229] font-normal p-4 capitalize px-3"
            >
              {props?.data?.data?.expirationTime}
            </Col>
          </Row>
          <Row className="border-t-[1px] border-l-[1px] border-r-[1px] border-[#030229]  border-opacity-5 rounded-md">
            <Col
              span={12}
              className="bg-[#F5F6FA]  p-4 text-[15px] text-[#605BFF] font-normal px-3"
            >
              Last Change
            </Col>
            <Col
              span={12}
              className="bg-white text-[15px] text-[#030229] font-normal p-4 capitalize px-3"
            >
              {props?.data?.lastChange}
            </Col>
          </Row>
          <Row className="border-t-[1px] border-l-[1px] border-r-[1px] border-[#030229]  border-opacity-5 rounded-md">
            <Col
              span={12}
              className="bg-[#F5F6FA]  p-4 text-[15px] text-[#605BFF] font-normal px-3"
            >
              Last Check-in
            </Col>
            <Col
              span={12}
              className="bg-white text-[15px] text-[#030229] font-normal p-4 capitalize px-3"
            >
              {props?.data?.lastCheckIn}
            </Col>
          </Row>
          <Row className="border-t-[1px] border-l-[1px] border-r-[1px] border-b-[1px] border-[#030229]  border-opacity-5 rounded-md">
            <Col
              span={12}
              className="bg-[#F5F6FA]  p-4 text-[15px] text-[#605BFF] font-normal px-3"
            >
              Device ID
            </Col>
            <Col
              span={12}
              className="bg-white text-[15px] text-[#030229] font-normal p-4 capitalize px-3"
            >
              -
            </Col>
          </Row></>
      )}

    </div>
  );
};
export default DeviceInfos;
