import { Paper, Stack, IconButton } from "@mui/material";
import { Dropdown, InputNumber, Select, Table, Tag, notification } from "antd";
import AddItem from "./AddReqItem";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { myQuotationActions } from "../../../store/quotation";
import { useNavigate } from "react-router-dom";
import {
  deleteRequestStockAction,
  getAllShopAction,
  getProductsByReqItemAction,
  getSelectedRequestAction,
  sendRequestAction,
} from "../../../store/channel/actions";
import DeleteIcon from "@mui/icons-material/Delete";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import type { MenuProps, TableColumnsType } from "antd";
import { searchValue } from "../../../utils/setColor";
import SettingsIcon from "@mui/icons-material/Settings";
import ExportCSVFile from "../../../components/buttons/ExportCSVFile";
import { StockRequestHeaders } from "../../../components/csvHeaders/Headers";
import { getActivePrice, searchFuction } from "../../../utils/converter";
import {
  deleteStockRequestItemAction,
  getAllshopEligibleToproductAction,
  quantityApprovedAction,
  shopTransferFromAction,
} from "../../../store/shop/actions";
import DeleteModal from "../../../components/Modals/DeleteModal";
import { getDefaultCurrencyCode } from "../../../utils/helper";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";

interface ExpandedDataType {
  key: React.Key;
  specification: string;
  sn: string;
  imei: string;
  status: any;
  data: any;
  price: any;
}

const UpdateSaveButtons = (props: any) => {
  const { auth, channel, shop } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shopId } = useParams();

  const handleSendRequest = async () => {
    if (auth?.token && channel?.selectedRfs?.list.length > 0) {
      const isEmailRequest = await sendRequestAction(
        channel.selectedRfs._id,
        {
          status:
            channel?.selectedRfs?.status === "review" ? "approved" : "review",
          approvedBy:
            channel?.selectedRfs?.status === "review" ? auth?.user?._id : null,
        },
        auth.token
      )(dispatch);

      isEmailRequest && navigate(-1);
    }
  };
  const handleSendRequestAsTransfer = async () => {
    if (props?.selectedShop === null && !props?.fromDifferentChannel) {
      return notification.error({ message: "Please Select Shop" });
    }
    if (auth?.token && channel?.selectedRfs?.list.length > 0) {
      const isEmailRequest = await sendRequestAction(
        channel.selectedRfs._id,
        {
          status:
            channel?.selectedRfs?.status === "review" ? "approved" : "review",
          approvedBy:
            channel?.selectedRfs?.status === "review" ? auth?.user?._id : null,
          isTransferred: true,
        },
        auth.token
      )(dispatch);

      isEmailRequest && navigate(-1);
    }
  };

  const handleMakeTranser = async () => {
    props?.setIsTransfer(true);
  };

  const handleCancelQuotation = async () => {
    auth?.token &&
      (await deleteRequestStockAction(
        auth?.token,
        channel?.selectedRfs?._id,
        `?shopId=${shopId}`
      )(dispatch));
    navigate(-1);
  };

  const dataToCheck = channel?.selectedRfs?.list?.map((el: any) => {
    return {
      data: el,
      key: el._id,
      type: el?.product?.product?.type,
      brand: el?.product?.product?.brand,
      qtyApproved: el?.qtyApproved ? el?.qtyApproved : 0,
      dispatch: dispatch,
      auth: auth,
      model: el?.product?.product?.model,
      specification: el?.product?.specification
        ?.slice(2)
        ?.map((el: any) => el[1] && `${el[0]}: ${el[1]}`)
        .join(", "),
      quantity: el?.qtyRequested,
      status: channel?.selectedRfs?.status,
      transferFrom: el?.shopToTransfer?.name,
      stockRequestId: channel?.selectedRfs?._id,
      productId: el?.product?._id,
    };
  });

  const checkApproved = () => {
    if (channel?.selectedRfs?.status?.toLowerCase() === "review") {
      return dataToCheck?.every((el: any) => el?.qtyApproved > 0);
    } else {
      return true;
    }
  };
  return (
    <Stack direction={"row"} spacing={2}>
      {!["sales-agent"].includes(auth.user?.role?.toLowerCase()) && (
        <div>
          {channel?.selectedRfs?.list.length !== 0 &&
            ["request", "review"].includes(
              channel?.selectedRfs?.status?.toLowerCase()
            ) && (
              <div className="space-x-4">
                {!props?.isTranfer && (
                  <LoadingButton
                    loading={channel?.isFetching || shop?.isFetching}
                    disabled={!checkApproved()}
                    loadingPosition="start"
                    color={
                      channel?.selectedRfs?.status?.toLowerCase() === "review"
                        ? "success"
                        : "primary"
                    }
                    variant="contained"
                    onClick={async () => {
                      await handleSendRequest();
                    }}
                  >
                    {channel?.selectedRfs?.status?.toLowerCase() === "review"
                      ? "Confirm As Stock Out"
                      : "Send Stock Request"}
                  </LoadingButton>
                )}
                {["review"].includes(
                  channel?.selectedRfs?.status?.toLowerCase()
                ) && (
                  <>
                    {props?.isTranfer ? (
                      <LoadingButton
                        loading={channel?.isFetching || shop?.isFetching}
                        loadingPosition="start"
                        color={
                          channel?.selectedRfs?.status?.toLowerCase() ===
                          "review"
                            ? "success"
                            : "primary"
                        }
                        variant="contained"
                        onClick={async () => {
                          await handleSendRequestAsTransfer();
                        }}
                      >
                        {channel?.selectedRfs?.status?.toLowerCase() ===
                          "review" && "Confirm"}
                      </LoadingButton>
                    ) : (
                      <LoadingButton
                        loading={channel?.isFetching || shop?.isFetching}
                        disabled={!checkApproved()}
                        loadingPosition="start"
                        color={
                          channel?.selectedRfs?.status?.toLowerCase() ===
                          "review"
                            ? "success"
                            : "primary"
                        }
                        variant="contained"
                        onClick={async () => {
                          await handleMakeTranser();
                        }}
                      >
                        {channel?.selectedRfs?.status?.toLowerCase() ===
                          "review" && "Transfer"}
                      </LoadingButton>
                    )}
                  </>
                )}
              </div>
            )}
        </div>
      )}
      {["sales-agent"]?.includes(auth?.user?.role?.toLowerCase()) && (
        <div>
          {channel?.selectedRfs?.list.length !== 0 &&
            ["request"].includes(
              channel?.selectedRfs?.status?.toLowerCase()
            ) && (
              <LoadingButton
                loading={channel?.isFetching || shop?.isFetching}
                loadingPosition="start"
                variant="contained"
                onClick={async () => {
                  await handleSendRequest();
                }}
              >
                {channel?.selectedRfs?.status?.toLowerCase() !== "review" &&
                  "Send Stock Request"}
              </LoadingButton>
            )}
        </div>
      )}
      <LoadingButton
        variant="contained"
        color="error"
        onClick={async () => await handleCancelQuotation()}
        loading={channel?.isFetching || shop?.isFetching}
      >
        Cancel
      </LoadingButton>
    </Stack>
  );
};

const PageView = (props: any) => {
  const { auth, quotation, channel, shop, shopEligible, company } = useSelector(
    (state: any) => state
  );
  const [expandedRowKey, setExpandedRowKey] = React.useState<any>(null);
  const [isCancel, setIsCancel] = useState(false);
  const [isTranfer, setIsTransfer] = useState(false);
  const [selectedShop, setSelectedShop] = useState(
    channel?.selectedRfs?.list?.at(0)?.shopToTransfer?._id ?? null
  );
  const [prodId, setProdId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [rowToDelete, setRowToDelete] = useState("");
  const channelId = channel?.selected?._id;
  const [showShops, setShowShops] = useState(false);
  const [currentRow, setCurrentRowData] = useState(null);
  const [shopFilter, setShopFilter] = useState([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [fromDifferentChannel, setFromDifferentChannel] = useState(false);
  const OPTIONS = ["Apples", "Nails", "Bananas", "Helicopters"];
  const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));

  const { rfsId } = useParams();
  const dispatch = useDispatch();
  const { Option } = Select;

  useEffect(() => {
    dispatch(myQuotationActions.setNew(null));
    dispatch(myQuotationActions.setSelected(null));

    if (auth?.token && rfsId) {
      getSelectedRequestAction(auth?.token, `?requestId=${rfsId}`)(dispatch);
    }
  }, [rfsId, auth?.token, dispatch]);

  React.useEffect(() => {
    auth?.token &&
      channelId &&
      getAllShopAction(auth?.token, `channel=${channelId}`)(dispatch);
  }, [auth?.token, channelId, dispatch]);

  useEffect(() => {
    if (auth?.token && prodId) {
      getProductsByReqItemAction(
        auth?.token,
        `requestedItem=${prodId}`
      )(dispatch);
    }
  }, [auth?.token, prodId, dispatch]);

  const expandedRowRender = () => {
    const columns: TableColumnsType<ExpandedDataType> = [
      {
        title: "Descriptions",
        dataIndex: "specification",
        key: "specification",
      },
      { title: "Serial Number", dataIndex: "sn", key: "sn" },
      {
        title: "IMEI",
        dataIndex: "imei",
        key: "imei",
        render: (text, record) => (
          <>
            <p>{record?.data?.wareHouseItem?.imei1 || "N/A"}</p>
            <p>{record?.data?.wareHouseItem?.imei2}</p>
          </>
        ),
      },
      {
        title: `Price(${getDefaultCurrencyCode(company)})`,
        dataIndex: "price",
        key: "price",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) => (
          <Tag
            color={searchValue(record?.data?.status)}
            className="capitalize min-w-[50px] text-center"
          >
            {record?.data?.status}{" "}
          </Tag>
        ),
      },
    ];

    const productData: ExpandedDataType[] = [];
    channel?.products?.data?.forEach((el: any) => {
      productData.push({
        key: el.id,
        sn: el?.wareHouseItem?.serialNumber,
        imei: el?.wareHouseItem?.imei,
        price: el?.requestedItem?.product?.prices
          ?.filter((d: any) => d.isActive === true)
          ?.map((d: any) => d.value)
          ?.toLocaleString(),
        specification: el?.wareHouseItem?.quotationItem?.specification
          ?.map((el: any) => el[1] && `${el[0]}: ${el[1]}`)
          .join(", "),
        status: el?.status,
        data: el,
      });
    });
    if (channel?.selectedRfs?.status === "done") {
      return (
        <Table columns={columns} dataSource={productData} pagination={false} />
      );
    }
  };

  const values = channel?.selectedRfs?.list;

  const shopOptions = channel?.allShop?.data?.map((el: any) => {
    return {
      values: el?._id,
      label: `${el?.name} ~ ${el?.type === "service-center" ? "SC" : "CS"}`,
    };
  });

  const shopOptionsEligible = shopEligible?.shopEligible?.data.flatMap(
    (item: any) => {
      return item._id.map((idItem: any) => {
        return {
          values: idItem?._id,
          label: idItem?.name,
          countPerShop: item?.count,
        };
      });
    }
  );

  const reqStockColumns: TableColumnsType<any> = [
    {
      dataIndex: "type",
      title: "Type",
      key: "type",
    },
    {
      dataIndex: "brand",
      title: "Brand",
      key: "brand",
    },
    {
      dataIndex: "model",
      title: "Models",
      key: "model",
    },
    {
      dataIndex: "specification",
      title: "Description",
      key: "specification",
    },
    {
      dataIndex: "specification",
      title: "Price/Product",
      key: "price",
      render: (text, record) => (
        <b>{getActivePrice(record?.data?.product?.prices)?.toLocaleString()}</b>
      ),
    },
    {
      dataIndex: "quantity",
      title: "Quantity",
      key: "quantity",
      render: (text, record) => {
        return (
          <InputNumber
            min={0}
            disabled={["review", "done", "approved"].includes(record.status)}
            defaultValue={parseInt(text)}
            onChange={(value: any) => {
              record.quantity = value;
            }}
            onBlur={async () => {
              const res = await quantityApprovedAction(
                record.key,
                { qtyRequested: record.quantity },
                record.auth.token
              )(record.dispatch);
              res && notification.success({ message: "Quantity Updated!" });
              !res &&
                notification.error({
                  message: "Quantity Failed tobe Updated!",
                });
            }}
          />
        );
      },
    },
    {
      title: "Quantity Approved",
      dataIndex: "qtyApproved",
      key: "qtyApproved",
      render: (text, record) => {
        if (
          record.status !== "review" ||
          record?.auth?.user?.role?.toLowerCase() === "sales-agent"
        ) {
          return text || "-";
        }
        return (
          <InputNumber
            min={0}
            // disabled={record.status !== "review"}
            defaultValue={parseInt(text)}
            onChange={(value: any) => {
              setShowShops(false);
              record.qtyApproved = value;
            }}
            onBlur={async () => {
              setShowShops(false);
              const res = await quantityApprovedAction(
                record.key,
                { qtyApproved: record.qtyApproved },
                record.auth.token
              )(record.dispatch);
              res && notification.success({ message: "Quantity Updatedsss!" });
              !res &&
                notification.error({
                  message: "Quantity Failed tobe Updated!",
                });
            }}
          />
        );
      },
    },
    {
      title: `${isTranfer ? "Transfer From" : ""}`,
      dataIndex: "shop",
      key: "shop",
      render: (text, record) => {
        return (
          <>
            {record?.isTranfer && (
              <>
                <Select
                  placeholder="Select Shop"
                  allowClear
                  onFocus={async () => {
                    const getterShop = await getAllshopEligibleToproductAction(
                      auth?.token,
                      record.productId
                    )(dispatch);
                    if (!getterShop) {
                      setCurrentRowData(record.productId);
                      setShowShops(false);
                    }
                  }}
                  onSearch={(value: any) =>
                    searchFuction(
                      value,
                      record.shop,
                      (option: any, val: any) =>
                        option?.label
                          ?.toLowerCase()
                          .includes(val.toLowerCase()),
                      setShopFilter
                    )
                  }
                  showSearch
                  optionFilterProp="children"
                  filterOption={false}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  style={{ width: 200 }}
                  onChange={async (values) => {
                    setShowShops(false);
                    shopTransferFromAction(
                      record?.key,
                      {
                        shopToTransfer: values,
                        stockRequest: record?.stockRequestId,
                        isTransferred: true,
                        transferStatus: "pending",
                      },
                      auth?.token
                    )(dispatch);
                    setSelectedShop(values);
                  }}
                >
                  {!showShops &&
                  currentRow !== record.productId &&
                  !shop.isFetching &&
                  shopFilter.length > 0
                    ? shopFilter.map((pro: any, index: number) => {
                        return (
                          <Select.Option key={index} value={pro.values}>
                            {pro?.label}({pro?.countPerShop})
                          </Select.Option>
                        );
                      })
                    : !showShops &&
                      currentRow !== record.productId &&
                      !shop.isFetching &&
                      record?.shop
                        ?.filter(
                          (d: any) =>
                            d.values !== channel?.selectedRfs?.shopId?._id
                        )
                        ?.map((d: any) => {
                          return (
                            <Select.Option
                              value={d?.values}
                              disabled={record?.qtyApproved > d?.countPerShop}
                            >
                              {d?.label}({d?.countPerShop})
                            </Select.Option>
                          );
                        })}
                </Select>
              </>
            )}
          </>
        );
      },
    },
    {
      title: "Actions",
      render: (text, record) => {
        const deleteModal = () => {
          record.setVisible(true);
        };
        const handleCancel = () => {
          record.setVisible(false);
          setSelectedShop(1);
        };
        const deleteRequest = async () => {
          record?.token &&
            (await deleteStockRequestItemAction(
              record.rowToDelete,
              record.token
            )(record.dispatch));
          record.setVisible(false);
        };
        if (
          !["draft", "review", "request"].includes(record.status?.toLowerCase())
        )
          return null;
        return (
          <>
            <IconButton aria-label="delete" size="small">
              <DeleteIcon
                fontSize="inherit"
                onClick={() => {
                  record.setRowToDelete(record.key);
                  deleteModal();
                }}
              />
            </IconButton>
            <DeleteModal
              visible={record.visible}
              onOk={deleteRequest}
              isLoading={record.isLoading}
              onCancel={handleCancel}
              itemName="Stock Item"
            />
          </>
        );
      },
    },
  ];
  // const checkNumber =channel?.selectedRfs?.list?.map((el:any)=>{

  // })
  const dataToDisplay = channel?.selectedRfs?.list?.map((el: any) => {
    return {
      data: el,
      key: el._id,
      type: el?.product?.product?.type,
      brand: el?.product?.product?.brand,
      qtyApproved: el?.qtyApproved ? el?.qtyApproved : 0,
      dispatch: dispatch,
      auth: auth,
      model: el?.product?.product?.model,
      specification: el?.product?.specification
        ?.slice(2)
        ?.map((el: any) => el[1] && `${el[0]}: ${el[1]}`)
        .join(", "),
      quantity: el?.qtyRequested,
      status: channel?.selectedRfs?.status,
      visible: visible,
      setVisible: setVisible,
      token: auth?.token,
      isLoading: shopEligible?.isFetching,
      rowToDelete: rowToDelete,
      setRowToDelete: setRowToDelete,
      shop: shopOptionsEligible,
      showShops: showShops,
      setShowShops: setShowShops,
      isTranfer: isTranfer,
      transferFrom: el?.shopToTransfer?.name,
      stockRequestId: channel?.selectedRfs?._id,
      productId: el?.product?._id,
    };
  });

  const handleSendEmail = async () => {
    console.log(quotation?.allRfqSupplier?.data?.map((d: any) => d._id));
  };

  const handleRowExpand = (expanded: any, record: any) => {
    if (expanded) {
      setExpandedRowKey(record.key);
      setProdId(record.key);
    } else {
      setExpandedRowKey(null);
    }
  };

  const csvData = dataToDisplay?.map((record: any) => ({
    shop: shop?.selected?.name || "N/A",
    type: record?.type || "N/A",
    brand: record.brand || "N/A",
    model: record.model || "N/A",
    specification: record.specification,
    qtyReq: record?.quantity,
    qtyAppreved: record?.qtyApproved,
  }));

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <ExportCSVFile
          csvHeaders={StockRequestHeaders}
          csvData={csvData || []}
          filename={`Shop Stock/Request.csv`}
        />
      ),
    },
  ];

  return (
    <div className="mt-8">
      <div className="mt-5 text-black flex items-center justify-between">
        <DetailsHeaderActionBar
          pageName={channel?.selectedRfs?.shopId?.name}
          title={`RFS ~ ${rfsId}`}
        />
        <Dropdown menu={{ items }} placement="bottomLeft" arrow>
          <SettingsIcon className="cursor-pointer" />
        </Dropdown>
      </div>
      {channel?.isFetching ? (
        <TableSkeleton />
      ) : (
        <>
          {" "}
          {channel?.selectedRfs?.status && (
            <Paper elevation={0} style={{ marginBottom: "20px" }}>
              <Stack spacing={0}>
                {["request", "review"].includes(
                  channel?.selectedRfs?.status
                ) && (
                  <AddItem
                    onClickAdd={() => {
                      setIsCancel(true);
                    }}
                    onConfirmChannel={setFromDifferentChannel}
                    isTranfer={isTranfer}
                  />
                )}

                <Table
                  columns={reqStockColumns}
                  dataSource={dataToDisplay}
                  expandable={{
                    expandedRowRender,
                    defaultExpandedRowKeys: ["0"],
                    onExpand: handleRowExpand,
                    expandedRowKeys: [expandedRowKey],
                  }}
                  loading={channel?.isFetching || shop?.isFetching}
                />
              </Stack>
            </Paper>
          )}
        </>
      )}

      {channel?.selectedRfs?.status &&
        values?.length >= 1 &&
        ["request", "review"].includes(channel?.selectedRfs?.status) && (
          <UpdateSaveButtons
            //  isCancel={isCancel}
            setIsTransfer={setIsTransfer}
            fromDifferentChannel={fromDifferentChannel}
            isTranfer={isTranfer}
            selectedShop={selectedShop}
            isLoading={isLoading}
            sendButtonDefault={false} //this will depend on quotation status
            onClick={() => {
              setIsCancel(false);
            }}
            handleSendEmail={handleSendEmail}
          />
        )}
    </div>
  );
};

export default PageView;
