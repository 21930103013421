import { Stack } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import RequestGrid from "../../../components/grids/RequestGrid";
import { Spin } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";

const PageView = () => {
  const { channel, layout } = useSelector((state: any) => state);

  return (
    <div>
      <Box>
        <span className=" text-black font-medium">
          Stock Requested:
          {channel.isFetching ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            channel?.reqStock?.result
          )}
        </span>
        <ScrollableFrame hidePagination>
          <div className={`grid ${layout?.isSideNavOpen? "sm:grid-cols-1 lg:grid-cols-2":"sm:grid-cols-2 lg:grid-cols-3"} xl:grid-cols-3 2xl:grid-cols-4 gap-4`}>
            <RequestGrid data={channel?.selectedRfs?.data} />
          </div>
        </ScrollableFrame>
      </Box>
    </div>
  );
};

export default PageView;
